<template>
    <div class="users container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        User Management
                        <div class="sub header">Manage system users here</div>
                    </h3>
                </div>
                <div class="right aligned column">
                    <button class="ui black small button" type="button" @click="addUser"><i class="fontello-plus icon"></i> New User</button>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        User Management
                                        <div class="sub header">Manage system users here</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="plus icon link" @click="addUser">
                                        <span>&plus;</span>
                                    </a>
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="ID, email, name..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="ID, email, name..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="first_name">Name</div>
                                <div class="item" data-value="id">ID No.</div>
                                <div class="item" data-value="username">Email</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <table class="ui very basic user middle aligned unstackable responsive table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Username</th>
                            <th>Outlet</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="users.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="user in users" :key="`user_tr_${user.id}`">
                                <td>
                                    <div class="ui user list">
                                        <router-link class="item" :to="(user.id !== _user.id)? {
                                                name: 'User',
                                                params: {
                                                    id: user.id
                                                }
                                            } : {}">
                                            <div class="image">
                                                <avatar :user="user" :initial-class="getInitialClass(user)"/>
                                            </div>
                                            <div class="middle aligned content">
                                                <div class="header">
                                                    {{user|displayname}}
                                                    <label class="ui tiny olive label" v-if="user.id === _user.id">You</label>
                                                    <label class="ui tiny red label" v-if="user.suspended">Suspended</label>
                                                </div>
                                                <div class="meta">
                                                    <div class="role">{{displayRole(user.role)}}</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </td>
                                <td>
                                    <a :href="`mailto:${user.username}`" style="word-break: break-word;">{{user.username}}</a> <label class="ui tiny label" v-if="!user.verified">Not Verified</label>
                                    <i class="check small circle teal icon" title="Verified User" v-else></i>
                                </td>
                                <td>
                                    {{user.outlet? displayOutlet(user.outlet).name : ""}}
                                </td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu" :class="{disabled: user.id === _user.id}">
                                        <div class="ui right dropdown fitted item">
                                            <i class="ellipsis vertical icon" :class="{teal: user.id !== _user.id, 'inverted grey': user.id === _user.id}"></i>
                                            <div class="menu" v-if="user.id !== _user.id">
                                                <router-link class="item" :to="{
                                                    name: 'User',
                                                    params: {
                                                        id: user.id
                                                    }
                                                }">View User</router-link>
                                                <a class="item" href="javascript:void(0);" @click="editUser(user)">Edit User</a>
                                                <a class="item" href="javascript:void(0);" @click="reactivateUser(user)" v-if="user.suspended">Reactivate User</a>
                                                <a class="item" href="javascript:void(0);" @click="suspendUser(user)" v-else>Suspend User</a>
                                                <a class="item" href="javascript:void(0);" @click="sendTemporaryPassword(user)">Send Temporary Password</a>
                                                <a class="item" href="javascript:void(0);" @click="sendVerificationEmail(user)" v-if="!user.verified">Re-send Verification Email</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No user found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="users.length">
                        <tr>
                            <th colspan="4" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div ref="usermodal" class="ui user modal">
            <div class="content">
                <h3 class="ui header">{{user_form.id? "Update User" : "Create New User"}}</h3>
                <div class="ui hidden divider"></div>
                <user-form :id="`userform${_uid}`" :user="user_form" @change:saving="userSavingListener" @save:user="userSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`userform${_uid}`" class="ui submit teal button" :class="{loading: saving}" :disabled="saving">{{user_form.id? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";
import roles from "@/assets/constants/role";

import Avatar from "@/components/Avatar";
import PaginationMenu from "@/components/PaginationMenu";

const UserForm = () => import("@/views/UserForm");

export default {
    name: "users",
    components: {
        Avatar,
        PaginationMenu,
        UserForm
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            saving: false,
            query: "",
            outlets: [],
            roles,
            users: [],
            user_form: {},
            sorting: {
                by: "created",
                order: "desc"
            },
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            opened_modals: [],
            history_popped: false,
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.$http.get("outlets").then(({ data }) => {
            this.outlets = data;
        }).catch((error) => {
            console.error(error);

            this.$toasted.error("Unable to list outlets.", {
                duration: 3000
            });
        });

        this.search_();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        this.$nextTick(() => {
            $(this.$refs.sortbydropdown).dropdown();
            $(this.$refs.usermodal).modal({
                closable: false,
                onVisible: () => {
                    history.pushState(null, "usermodal_opened");
                    this.opened_modals.push("usermodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "usermodal";
                    });
                }
            });
        });
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu:not(.disabled) .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order
            };

            if(this.query) {
                search_params.query = this.query;
            }

            return search_params;
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        addUser() {
            this.user_form = {};
            $(this.$refs.usermodal).modal("show");
        },
        userSavingListener(saving) {
            this.saving = saving;
        },
        userSaved(user) {
            $(this.$refs.usermodal).modal("hide");
            this.search_();
        },
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("users", {
                params
            }).then((response) => {
                this.users = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.users.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list users. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        getInitialClass(user) {
            let classes = ["circular"];

            const colours = ["red", "orange", "yellow", "olive", "green", "teal", "blue", "violet", "purple", "pink", "brown"];
            if(!user.suspended) {
                const index = user.id % colours.length;
                classes.push([colours[index]]);
            }
            return classes;
        },
        getInitial(user) {
            let first_name = user?.first_name || "";
            let last_name = user?.last_name || "";

            return `${first_name.charAt(0)}${last_name.charAt(0)}`;
        },
        displayRole(role) {
            return `${role.charAt(0).toUpperCase()}${role.toLowerCase().slice(1)}`;
        },
        displayOutlet(outlet) {
            return this.outlets.find((_outlet) => {
                return outlet === _outlet.id;
            }) || {
                name: outlet
            };
        },
        editUser(user) {
            this.user_form = Object.assign({}, user);
            $(this.$refs.usermodal).modal("show");
        },
        reactivateUser(user) {
            this.$confirm({
                title: "Reactivate User",
                message: `Are you sure you want to reactivate user <strong>${this.$options.filters.displayname(user)}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.put(`users/${user.id}`, {
                            suspended: false
                        }).then((response) => {
                            this.refreshUser(user.id);

                            this.$toasted.success(`User ${this.$options.filters.displayname(user)} reactivated successfully.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to reactivate user ${this.$options.filters.displayname(user)}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        suspendUser(user) {
            this.$confirm({
                title: "Suspend User",
                message: `Are you sure you want to suspend user <strong>${this.$options.filters.displayname(user)}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.put(`users/${user.id}`, {
                            suspended: true,
                            suspension_remark: `User suspended by ${this.$options.filters.displayname(this._user)}`
                        }).then((response) => {
                            this.refreshUser(user.id);

                            this.$toasted.success(`User ${this.$options.filters.displayname(user)} suspended successfully.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to suspend user ${this.$options.filters.displayname(user)}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        sendTemporaryPassword(user) {
            this.$confirm({
                title: "Send Temporary Password",
                message: `Are you sure you want to send temporary password to user <strong>${this.$options.filters.displayname(user)}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.post("users/temp-password", {
                            email: user.username
                        }).finally(() => {
                            this.$toasted.show(`Request to send temporary password to ${user.username} is being processed.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        sendVerificationEmail(user) {
            this.$confirm({
                title: "Send Verification Email",
                message: `Are you sure you want to send verification email to user <strong>${this.$options.filters.displayname(user)}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.post(`users/${user.id}/verification-email`).then(() => {
                            this.$toasted.show(`Request to send verification email to ${user.username} is being processed.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);
                            this.$toasted.error(`Unable to complete request to send verification email to ${user.username}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        refreshUser(id) {
            this.$http.get(`users/${id}`).then((response) => {
                Object.assign((this.users.find((user) => {
                    return user.id === id;
                }) || {}), response.data);
            }).catch((error) => {
                console.error(error);
            });
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.query !== _search_params.query) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.users.container {
    background: #fff;
}

.user.list {
    .image {
        ::v-deep {
            .avatar {
                width: 3em;
                height: 3em;
            }
        }
    }
    .header {
        color: inherit;
    }

    .meta {
        margin-top: 0.25em;
        color: rgba(0,0,0,.4);
    }
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:not(:first-child):not(:last-child) {
                    padding-left: 4em;
                }

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>